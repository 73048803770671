import React, { useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Unreadmsg } from "../views/messageview/Unreadmsg";
import { Recentmessage } from "../views/messageview/Recentmessage";
import { VideoCallPage } from "../views/messageview/VideoCall";

export const MsgRequestNav = () => {
  const navigate = useNavigate();
  const [all, setall] = useState("#292d31");
  const [accepted, setaccepted] = useState("");

  return (
    <div className="w-screen md:w-11/12 lg:w-10/12 xl:w-10/12 mx-auto  bg-black ">
      <div className="pb-4 overflow-auto flex-col items-center w-full mt-1">
        <div className=" overflow-auto flex justify-evenly">
          <button
            className="text-slate-400 mt-3  w-1/4 rounded-lg py-1 px-6"
            onClick={(e) => {
              setall("#292d31");
              setaccepted("");
              navigate("recentmessage/");
            }}
            style={{ backgroundColor: `${all}` }}
          >
            <div className="flex justify-center">
              <p className="text-center text-xs">Recent</p>
            </div>
          </button>

          <button
            className="text-slate-400 mt-3  rounded-lg py-2 px-6"
            onClick={(e) => {
              setall("");
              setaccepted("#292d31");
              navigate("unreadmsg/");
            }}
            style={{ backgroundColor: `${accepted}` }}
          >
            <div className="flex justify-center">
              <p className="text-center text-xs">Unread</p>
            </div>
          </button>
        </div>

        <Routes>
          <Route path="recentmessage/" element={<Recentmessage />} />
          <Route path="unreadmsg/" element={<Unreadmsg />} />
          <Route path="videocall/" element={<VideoCallPage />} />
        </Routes>
      </div>
    </div>
  );
};
