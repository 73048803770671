import React, { useEffect, useState, useRef } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Recentmessage } from "./Recentmessage";
import { Unreadmsg } from "./Unreadmsg";
import { Favmsg } from "./Favmsg";
import { useSelector, useDispatch } from "react-redux";
import PacmanLoader from "react-spinners/DotLoader";
import { getmsgnitify } from "../../app/features/message/messageSlice";
import { RecentList } from "./List/RecentList";
import { MsgRequestNav } from "../../navs/MsgRequestNav";
import { Gennavigation } from "../../navs/Gennav";
import { FaAngleLeft } from "react-icons/fa";
import { BottomNav } from "../../navs/BottomNav";


export const MessageView = () => {
  const login = useSelector((state) => state.register.logedin);
  const recentmsg = useSelector((state) => state.message.recentmsg);
  const navigate = useNavigate();
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#c2d0e1");
  const token = useSelector((state) => state.register.refreshtoken);
  const msgnotifystatus = useSelector((state) => state.message.msgnotifystatus);
  const messageList = useSelector((state) => state.message.Allmsg);
  let userid = useSelector((state) => state.register.userID);
  let ref = useRef(true);
  let dispatch = useDispatch();
  let [Chatmessage, setChatmessage] = useState("");
  const [click,setclick] = useState(true)
  useEffect(() => {
    if (!login) {
      window.location.href = "/";
    } //else {
    //   dispatch(getmsgnitify({ userid, token }));
    // }
  }, []);

  const shownote = () => {
    if (loading === false) {
      console.log("notification length " + recentmsg.length);
      if (recentmsg.length > 0) {
        return recentmsg.map((value) => {
          if (value.value === "notify") {
            return (
              <Unreadmsg
                photolink={value.photolink}
                username={value.username}
                content={value.content}
                fromid={value.fromid}
                toid={value.toid}
                dates={value.date}
                count={value.messagecount}
              />
            );
          } else if (value.value === "recent") {
            console.log("inside recent");
            return (
              <RecentList
                fromid={value.fromid}
                toid={value.toid}
                contents={value.content}
                name={value.name}
                image={value.photolink}
                
              />
            );
          }
        });
      } else {
        return (
          <div className="flex flex-col justify-center items-center overflow-hidden">
            <p className="text-slate-400 mt-16">No! Messages</p>
          </div>
        );
      }
    }
  };

  const checknotification = () => {
    if (recentmsg.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (msgnotifystatus === "succeeded") {
      setLoading(false);
    }
  }, [msgnotifystatus]);
  return (
    <div className="w-screen sm:w-8/12 md:w-8/12 lg:w-7/12 xl:w-7/12 md:mr-auto  bg-black ">
                <div className="chat_nav"><Gennavigation/></div>
<div className="md:w-3/5 md:mx-auto">
      
       <header className="flex gap-4 items-center md:ml-10">
        <FaAngleLeft
          color="white"
          size={30}
          onClick={() => {
            navigate("/");
          }}
        />

        <h4 className="font-bold text-lg text-white">MESSAGES</h4>
      </header>

      <div className="sticky z-10 top-0   " onClick={(e)=>setclick(true)}>
        <div className="px-2 md:px-4  sm:p-6  lg:pl-10 pb-2 bg-black ">
        
          <input
            type="text"
            className="inpt w-full pl-4 sm:pl-6 rounded-2xl"
            placeholder="Search for message"
          />
        </div>
        <MsgRequestNav />
      </div>

      {/* Move scrollable content here */}
      <div className="overflow-y-auto h-[calc(100vh-100px)] pb-3">
        <div className="flex flex-col w-full">
          {loading && (
            <div className="flex flex-col items-center mt-5">
              <PacmanLoader
                color={color}
                loading={loading}
                size={35}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
              <p className="text-center text-slate-400 text-xs">
                getting message...
              </p>
            </div>
          )}
        </div>
      </div></div>
      {/* <div className="md:hidden"><BottomNav/></div> */}
    </div>
  );
};
