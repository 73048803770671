import React, { useEffect, useState, useRef } from "react";
import { format } from "date-fns";
import comment from "../../../icons/commentIcon.png";
import like from "../../../icons/likeIcon.svg";
import likedicon from "../../../icons/likedIcon.svg";
import Options from "../../../icons/menu.svg";
import { downloadImage, deleteImage } from "../../../api/sendImage";
import person from "../../../icons/icons8-profile_Icon.png";
import { Commmentpage } from "../Commmentpage";
import { toast } from "react-toastify";
import { getpostcomment } from "../../../app/features/comment/comment";
import { useDispatch, useSelector } from "react-redux";
import unfollowIcon from "../../../icons/transparentstar.png"
import followIcon from "../../../icons/star.png"
import {
  resetcomment,
  clearcomment,
} from "../../../app/features/comment/comment";
import "../../../navs/Navs.css";
import deleteicon from "../../../icons/deleteicon.svg";
import {
  deletepost,
  PostchangeStatus,
  emptypostphoto,
} from "../../../app/features/post/post";
import { postlike, chagelikestatus } from "../../../app/features/like/like";
import { getpostbyid, getallpost } from "../../../app/features/post/post";
import { useNavigate } from "react-router-dom";
import { resetprofilebyid } from "../../../app/features/profile/comprofile";
import { follow, unfollow, ProfilechangeStatus } from "../../../app/features/profile/profile";
import { useCall } from "../../messageview/context";
import { BottomNav } from "../../../navs/BottomNav";
import { motion, AnimatePresence } from "framer-motion";


export const Postlist = ({
  userphoto,
  username,
  datetime,
  likes,
  comments,
  postphoto,
  content,
  key,
  posttype,
  postlog,
  postuserid,
  likelist,
  nickname,
  isfollow
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.register.refreshtoken);
  const userid = useSelector((state) => state.register.userID);
  const allcommentstatus = useSelector(
    (state) => state.comment.allcommentstatus
  );
  const likestatus = useSelector((state) => state.like.likestatus);
  const poststatus = useSelector((state) => state.post.getpostbyidstatus);
  const profile = useSelector((state) => state.comprofile.profile);
  const follow_stats = useSelector((state) => state.profile.follow_stats);
  const unfollow_stats = useSelector((state) => state.profile.unfollow_stats);
   const allPost = useSelector((state) => state.post.allPost)
  let myNick = ""

  const ref = useRef(true);
  const { toggleoption , opening,trackopen,settrackOpens,closehomeopts} = useCall()

  let count = 0;

  const error = useSelector((state) => state.comment.error);
  const errorpost = useSelector((state) => state.post.error);
  const likeerror = useSelector((state) => state.like.error);
  const deletepostsatus = useSelector((state) => state.post.deletepostsatus);
  const [following , set_following] = useState(unfollowIcon)
  // console.log("date time "+datetime)
  let date1 = new Date(parseInt(datetime));
  //console.log('post date '+ date)
  const date = format(date1, "MM/dd/yyyy 'at' h:mm a");
  const [postuser, setpostuser] = useState(person);
  const [file, setfile] = useState();
  const [alreadylike, setalreadylike] = useState(false);
  const [likephoto, setlikephoto] = useState(like);
  const [showComments, setShowComments] = useState();
  const [isFollowing, set_isFollowing] = useState(false)
  const [loading, setLoading] = useState(true)
  const [isOpen, setisOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState(null);

  if (!likes) {
    likes = "0";
  }

  if (!comments) {
    comments = "0";
  }

  const getphotos = async () => {
    if (userphoto) {
      setpostuser(downloadImage(userphoto, "profile"));
    }
    setfile(downloadImage(postphoto, "post"));
  };

  const enableDelete = () => {
    if (userid === postuserid) {
      return true;
    } else {
      return false;
    }
  };

  const followButton = async ()=>{
    if(isFollowing === true){
      if(unfollow_stats !== "loading"){
        set_following(unfollowIcon)
      
       dispatch(unfollow({userid:postuserid, followerid:userid, token}))
      }
     }else if(isFollowing === false){
       if(follow_stats !== "loading"){
        set_following(followIcon)
         
         dispatch(follow({userid:postuserid, followerid:userid, token}))
        }
     }
  }

  const enableFollow = () => {
    if(!userid){
      return false
    }
    if (userid === postuserid) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (allcommentstatus === "succeeded") {
      dispatch(resetcomment("idle"));
    }

    if (allcommentstatus === "failed") {
      dispatch(resetcomment("idle"));
      dispatch(clearcomment());
    }

    if (deletepostsatus === "succeeded") {
      toast.dismiss();
      dispatch(PostchangeStatus("idle"));
      dispatch(emptypostphoto());
    }

    if (deletepostsatus === "failed") {
      toast.error(`${errorpost}`);
      dispatch(PostchangeStatus("idle"));
      dispatch(emptypostphoto());
    }

    if (likestatus === "succeeded") {
      if (poststatus !== "loading") {
        dispatch(getpostbyid({ postid: postlog, token }));
      }

      dispatch(chagelikestatus("idle"));

      toast.dismiss();
    }

    if (likestatus === "failed") {
      // toast.error(`${likeerror}`)

      if (poststatus !== "loading") {
        dispatch(getpostbyid({ postid: postlog, token }));
      }
      dispatch(chagelikestatus("idle"));
    }

    if (unfollow_stats === "succeeded") {
      dispatch(getallpost({ userid }));
      set_isFollowing(false)
      dispatch(ProfilechangeStatus("idle"))
     
    }

    if (follow_stats === "succeeded") {
      dispatch(getallpost({ userid }));
      set_isFollowing(true)
      dispatch(ProfilechangeStatus("idle"))
    }

    

  }, [allcommentstatus, deletepostsatus, likestatus, unfollow_stats, follow_stats]);

  useEffect(() => {

    if(isfollow){
      set_isFollowing(true)
      set_following(followIcon)
    }else{
      set_isFollowing(false)
      set_following(unfollowIcon)
    }
    getphotos();
    checkmyLike();



    //  (getuserName())
  }, []);

  useEffect(() => {
    if (alreadylike === true) {
      setlikephoto(likedicon);
    }

    if (alreadylike === false) {
      setlikephoto(like);
    }
  }, [alreadylike]);

  const isFileImage = () => {
    if (posttype === "text") return null;

    if (posttype === "image") {
      return (
        <img
          onClick={() => setPreviewImage(file)}
          className="object-cover mx-auto rounded-xl mt-1 w-60 h-80 cursor-pointer"
          alt="post image"
          src={file}
        />
      );
    }
  
    if (posttype === "video") {
      return (
        <video
          className="object-cover w-60 h-80 mx-auto rounded-xl mt-1"
          src={file}
          controls
          controlsList="nodownload"
        />
      );
    }
    // if (posttype === "text") {
    //   return <></>;
    // } else if (posttype === "image") {
    //   return (
    //     <img
    //       className="object-cover mx-auto rounded-xl mt-1 w-60 h-80"
    //       alt="post image"
    //       src={file}
    //     ></img>
    //   );
    // } else if (posttype === "video") {
    //   console.log("video format" + file);
    //   return (
    //     <video
    //       className="object-cover w-60 h-80 mx-auto rounded-xl mt-1"
    //       alt="post image"
    //       src={file}
    //       controls
    //       controlsList="nodownload"
    //     ></video>
    //   );
    // }
  };

  const deletepostbyid = () => {
    if (deletepostsatus !== "loading") {
      toast.info("deleting post please wait a moment...", { autoClose: false });
      dispatch(deletepost({ postid: postlog, token }));
    }
  };

  const checkmyLike = () => {
    let mylike = likelist.find((value) => {
      return value.userid === userid;
    });

    if (mylike) {
      setalreadylike(true);
      setlikephoto(likedicon);
    } else {
      setalreadylike(false);
      setlikephoto(like);
    }
  };

  const postmylike = () => {
    if (likestatus !== "loading") {
      dispatch(postlike({ userid, postid: postlog, token }));
    }
  };

  const toggleComments = () => {
    setShowComments(!showComments);
    if (!showComments) {
      dispatch(clearcomment());
      dispatch(getpostcomment({ token, postid: postlog }));
    }
  };

  const toggleopts = ()=>{
    if(isOpen === true){
      settrackOpens(false)
      setisOpen(trackopen)
      return
    }
    setisOpen(true)
    settrackOpens(true)
    


  }

  useEffect(()=>{
    if(isOpen === true){
      
      setisOpen(trackopen)
      
    }
  })

  return (
    <li className="text-wrap mt-3 border border-slate-500 rounded-2xl mb-10">
      <div className="flex flex-col overflow-hidden pr-3">
        <div className="flex justify-between">
          <button
            className="flex flex-row pt-1"
            onClick={(e) => {
              dispatch(resetprofilebyid());
              navigate(`/profile/${postuserid}`);
            }}
          >
            <div className="w-10 h-10 bg-slate-500 rounded-full shrink-0 ">
              <img
                alt="post image"
                src={postuser}
                className="w-10 h-10 object-cover rounded-full shrink-0"
              />
            </div>

            <div className="flex flex-col ml-1">
              <div className=" text-slate-200 font-semibold text-xs block text-start">
                {username}
              </div>
              <div className=" text-slate-400  text-xs text-start block">
                {nickname}
                
              </div>
            </div>
          </button>

          {enableFollow() && (
            <button className="btn p-1 pt-0 pb-0 mt-1 mr-2 rounded-2xl text-xs mb-1" onClick={followButton}>
              <img alt="followIcon" src={following} className="w-5 h-5">
              </img>
            </button>
          )}

         
        </div>

        <p className="w-full max-w-xs sm:max-w-sm md:max-w-full lg:max-w-auto lg:pr-4 text-white mt-1 mx-2 break-words whitespace-normal text-sm overflow-hidden">
          {content}
          
        </p>

        <p className="text-slate-400 text-xs ml-2 mr-2">
          Posted<span> {date}</span>
        </p>

        {isFileImage()}

        <div className="flex place-content-between pt-2 pb-1 ml-1 mr-1 border-b-1 border-slate-500">
          <div className="flex gap-4 items-center">
            <button className="flex" onClick={toggleComments}>
              <img alt="commentIcon" src={comment} className="w-7 h-6" />
              <p className="text-slate-300 text-sm">{comments}</p>
            </button>

            <button
              className="flex"
              onClick={() => {
                postmylike();
                setalreadylike(!alreadylike);
              }}
            >
              <img alt="likeIcon" src={likephoto}></img>
              <p className="text-slate-300 text-sm">{likes}</p>
            </button>
          </div>

          <div>
            <div className="relative inline-block text-left">
              <button onClick={toggleopts} className="px-2">
                <img alt="options" src={Options} />
              </button>

              {isOpen && (
                <div
                  className="absolute flex flex-col right-0 bottom-3 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button"
                >
                  <div className="py-1 " role="none">
                    {/* {enableFollow() && (<a
                      href="#"
                      className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                      role="menuitem"
                    >
                      Report
                    </a>)}
                    <a
                      href="#"
                      className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                      role="menuitem"
                    >
                      Share
                    </a> */}
                   {enableFollow() && (<a
                      href="#"
                      className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                      role="menuitem"
                    >
                      Block User
                    </a>)}

                    {enableDelete() && (<a
                      
                      className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                      role="menuitem" onClick={(e)=>deletepostbyid()}
                    >
                      Delete Post
                    </a>)}
                   
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <AnimatePresence>
  {previewImage && (
    <motion.div
      key="image-modal"
      className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50"
      onClick={() => setPreviewImage(null)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.img
        src={previewImage}
        alt="Full Preview"
        className="max-w-full max-h-full object-contain rounded-lg"
        // onClick={(e) => e.stopPropagation()} 
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.8, opacity: 0 }}
        transition={{ duration: 0.3 }}
      />
    </motion.div>
  )}
</AnimatePresence>

        {showComments && (
          <div>
            <Commmentpage
              content={content}
              file={file}
              type={posttype}
              username={username}
              comments={comments}
              likes={likes}
              userphoto={postuser}
              postid={postlog}
            />
          </div>
        )}
      </div>
    </li>
  );
};
